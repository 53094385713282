.talents {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.row-flex {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    align-items: center;
}
