.description-characteristic {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.description-appearance {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.description-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
}

.description-characteristic h6 {
    margin-right: 5px;
}
