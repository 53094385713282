.equipment {
    display: flex;
    flex-direction: row;
}


.add-weapon-dialog {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: center;
    justify-content: space-between
}

.add-weapon-dialog div {
    margin-top: 5px;
}
