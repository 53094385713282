.card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    align-content: center;
    flex-wrap: nowrap;
    margin: 15px;
}

.character-name {
    font-size: 32px;
    width: 100%;
}

.card-actions {
    font-size: 16px;
}
