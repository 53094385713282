.title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.content {
    display: flex;
    flex-direction: column;
}


.footer {
    display: flex;
    flex-direction: column;
}
