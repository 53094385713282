.melee-actions {
    display: flex;
    flex-direction: row;
    border-left: 1px solid;
}

.melee-actions span {
    margin-left: 5px;
}

.attack-description {
    padding: 15px;
}
